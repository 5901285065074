import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Link } from "gatsby"

const Smile = () => {
  const image =
    "https://res.cloudinary.com/nuvolum/image/upload/v1653088711/OSG/DEV/LipBalm/OSG-Campaign-LipBalm-image01.jpg"
  return (
    <Layout pageTitle="smile-page" language={"en"}>
      <SEO
        lang="en"
        title="#MyOSGSmile | Oral Surgery Group Lip Balm Contest"
        description="Show us where your smile takes you! Post a pic with your #MyOSGSmile and you could win $100."
      />
      <div className="smile-22">
        <div className="smile-22__header">
          <h1>Where Will Your Smile Take You?</h1>
        </div>
        <div className="smile-22__image-text">
          <div className="smile-22__image-text-left">
            <img src={image} alt="#MyOSGSmile" />
          </div>
          <div className="smile-22__image-text-right">
            <p>
              We’re giving away a $100 gift card every season to someone who
              posts a picture or video with their OSG lip balm. From your
              favorite coffee shop to traveling across the world, we want to see
              your greatest adventures after oral surgery!
            </p>
          </div>
        </div>
        <div className="smile-22__steps-section">
          <h2>To enter:</h2>
          <div className="smile-22__steps-grid">
            <div className="smile-22__step">
              <div className="smile-22__step-icon">
                <img
                  src="https://res.cloudinary.com/nuvolum/image/upload/v1653088711/OSG/DEV/LipBalm/icon-follow.svg"
                  alt="step-1-icon"
                  id="step-1-icon"
                />
              </div>
              <div className="smile-22__step-text">
                <p>1.</p>
                <p>
                  Follow us on{" "}
                  <a
                    href="https://www.facebook.com/NJoralsurgerygroup/?fref=ts"
                    title="Follow Us On Facebook"
                    target="_blank"
                  >
                    Facebook
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://www.instagram.com/oralsurgerygroup/"
                    title="Follow Us On Instagram"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </p>
              </div>
            </div>
            <div className="smile-22__step">
              <div className="smile-22__step-icon">
                <img
                  src="https://res.cloudinary.com/nuvolum/image/upload/v1653088711/OSG/DEV/LipBalm/icon-post.svg"
                  alt="step-2-icon"
                  id="step-2-icon"
                />
              </div>
              <div className="smile-22__step-text">
                <p>2.</p>
                <p>
                  Post a video or pic on any social media platform with your OSG
                  lip balm using the hashtag #MyOSGSmile
                </p>
              </div>
            </div>
            <div className="smile-22__step">
              <div className="smile-22__step-icon">
                <img
                  /* Dev Note: the svg version italicizes the @ for some reason -JL */
                  src="https://res.cloudinary.com/nuvolum/image/upload/v1653587091/Programs/step-3-icon.png"
                  alt="step-3-icon"
                  id="step-3-icon"
                />
              </div>
              <div className="smile-22__step-text">
                <p>3.</p>
                <p>Tag us @oralsurgerygroup</p>
              </div>
            </div>
          </div>
        </div>
        <div className="smile-22__green-hl" />
        <div className="smile-22__conditions-section">
          <p>
            Your post must be public to qualify. If you have a private account,
            screenshot your post and email it to <a href="mailto:jaclyn@oralsurgerygroup.com">jaclyn@oralsurgerygroup.com</a> to
            enter. View official{" "}
            <a href="/smile-terms-conditions" title="View Our Terms & Conditions">
              Terms & Conditions here
            </a>
            .
          </p>
          <div className="smile-22__sharing-smiles-box">
            <h3>Sharing Smiles</h3>
            <div className="smile-22__sharing-smiles-icon">
              <img
                src="https://res.cloudinary.com/nuvolum/image/upload/v1653592412/Programs/sharing-smiles-icon.svg"
                alt="sharing-smiles-icon"
              />
            </div>
            <p>
              Along with rewarding our winner, we’re also donating 50 lip balms
              to a local homeless shelter every season. We will select a
              different shelter each time to help more people in need throughout
              our community.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Smile
